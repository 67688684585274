import React from 'react';
// import logo from './logo.svg';
import './App.css';
import Icon from '@mdi/react';
import { mdiAccount, mdiAccountMultiple, mdiTimer } from '@mdi/js';

import { useLocation, BrowserRouter as Router } from 'react-router-dom';

import moment from 'moment';

function Passport(props) {
  // console.log(props);
  return (
    <div className="App">
      <div className="real-header">
        本科用户，{props.direction}校授权有效！
      </div>
      <div className="real-body">
        <hr className="real-divider" />
        <div className="listitem">
          <div className="listaction">
            <div className="listicon">
              <Icon path={mdiAccount} size={1} />
            </div>
            <span className="listdesc">用户姓名</span>
          </div>
          <div className="listcontent">
            <h2>{props.name}</h2>
          </div>
        </div>
        <div className="listitem">
          <div className="listaction">
            <div className="listicon">
              <Icon path={mdiAccountMultiple} size={1} />
            </div>
            <span className="listdesc">登记类型</span>
          </div>
          <div className="listcontent">
            <h2>本科生</h2>
          </div>
        </div>
        <div className="listitem">
          <div className="listaction">
            <div className="listicon">
              <Icon path={mdiTimer} size={1} />
            </div>
            <span className="listdesc">登记时间</span>
          </div>
          <div className="listcontent">
            <div className="timewrapper">
              <div className="timebox">
                <div className="marquee">{props.time}</div>
                <div>{props.time}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <p>Have a try!</p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header> */}
    </div>
  );
}

function Screen() {
  let query = new URLSearchParams(useLocation().search);
  const direction = query.get('a');
  const name = query.get('b');
  const timestr = moment().format('YYYY-MM-DD HH:mm:ss');
  return (<Passport direction={direction} name={name} time={timestr} />);
}

function App() {
  return (
    <Router>
      <Screen />
    </Router>
  );
}

export default App;
